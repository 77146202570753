<section class="subscribe-area">
    <div class="container">
        <div class="subscribe-inner-area">
            <div class="section-title">
                <h2>Stay Tuned</h2>
                <p>Subscribe my newsletter and don’t miss any update on new products, promotions or even career events.</p>
            </div>

            <form class="newsletter-form">
                <input type="email" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                <button type="submit">Subscribe To Newsletter</button>

                <div class="check-info">
                    <input class="inp-cbx" id="cbx" type="checkbox">
                    <label class="cbx" for="cbx">
                        <span>
                            <svg width="12px" height="9px" viewbox="0 0 12 9">
                                <polyline points="1 5 4 8 11 1"></polyline>
                            </svg>
                        </span>
                        <span>I read and accept the <a href="#">privacy policy.</a></span>
                    </label>
                </div>
            </form>

            <div class="subscribe-shape1"><img src="assets/img/football1.png" alt="image"></div>
            <div class="subscribe-shape2"><img src="assets/img/football2.png" alt="image"></div>
        </div>
    </div>
</section>