import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

const firebaseConfig = {
  apiKey: "AIzaSyAte7jVvB4MFSOEqFsNDTBDqCmSMHnBudk",
  authDomain: "fantasy-premier.firebaseapp.com",
  projectId: "fantasy-premier",
  storageBucket: "fantasy-premier.appspot.com",
  messagingSenderId: "976069547491",
  appId: "1:976069547491:web:df205a162da16e38c47cd6",
  measurementId: "G-274Q94Y993"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
