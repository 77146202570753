<section id="gallery" class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Photo Gallery</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
        </div>

        <div class="gallery-slides owl-carousel owl-theme">
            <div class="single-gallery-item" *ngFor="let team of teams; let i = index;">
                <!--<img src="assets/img/gallery/img1.jpg" alt="image">-->
                <p>{{team.team}}</p>
                <small>{{team.player}}</small>
                <!--<a href="assets/img/gallery/img1.jpg" class="link-btn popup-btn"></a>-->
            </div>

        </div>
    </div>

    <!--<div class="gallery-shape1"><img src="assets/img/player2.png" alt="image"></div>-->
</section>