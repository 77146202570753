import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { TournamentsComponent } from './components/pages/tournaments/tournaments.component';
import { TeamsComponent } from './components/pages/teams/teams.component';
import { MaintenanceComponent } from './components/pages/maintenance/maintenance.component';
import { RulesComponent } from './components/pages/rules/rules.component';
import { SingleTournamentComponent } from './components/pages/single-tournament/single-tournament.component';
import { PrivacyPolicyComponent } from './components/layouts/privacy-policy/privacy-policy.component';

const routes: Routes = [
    {path: '', component: HomeComponent},
    //{path: 'home', component: HomeComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'competicoes', component: TournamentsComponent},
    {path: 'competicoes/:id', component: SingleTournamentComponent},
    {path: 'equipas', component: TeamsComponent},
    {path: 'regulamentos', component: RulesComponent},
    {path: 'politica-privacidade', component: PrivacyPolicyComponent},
    { path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }