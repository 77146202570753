<section id="partners" class="teams-area pb-70">
    <!--bg-161616-->
    <div class="row">
        <div class="col-md-12 p-0">
            <div class="teams-details-header">
                <img src="../../../../assets/img/partner/home_teams.png" class="img-fluid" alt="">

            </div>
        </div>
    </div>
    <div class="container">
        <div class="section-title">
            <h2>Equipas</h2>
            <!--<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>-->
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-4 col-md-4 col-12" *ngFor="let team of teams; let i = index;">
                <div class="single-teams-box">
                    <a href="#">
                        <img class="icon-player" [src]="team.pic + '?alt=media&token=e7897702-6859-49eb-ab07-8fca84e728ac'" alt="image">
                        <p class="mb-0">{{ team.team }}</p>
                        <small>{{ team.player }}</small>
                        <div class="general-results d-flex flex-row justify-content-between">
                            <div class="results pe-3"><span><img src="../../../../assets/img/Leao-icon.svg" alt=""></span>{{team.classificacao_geral.total_points}}</div>
                            <div class="results pe-3"><span><img src="../../../../assets/img/LigaCampeoes-icon.svg" alt=""></span>0000</div>
                            <div class="results pe-3" [class.inactive]="!team.ricardo_silva.active"><span><img src="../../../../assets/img/RicardoSilva-icon.svg" alt=""></span>{{team.ricardo_silva.total_points}}</div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>