import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/shared/database.service';

@Component({
  selector: 'app-main-banner',
  templateUrl: './main-banner.component.html',
  styleUrls: ['./main-banner.component.scss']
})
export class MainBannerComponent implements OnInit {

  tournaments: any[];

  constructor(private db: DatabaseService) {
    this.db.getTournaments(true).then((tournaments: any) => {
      this.tournaments = tournaments;
    })
  }

  ngOnInit(): void {
  }

}
