<div id="home" class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div id="mainCarouselDesktop" class="carousel slide carousel-fade main-banner-content desktop h-100" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#mainCarouselDesktop" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Top 3"></button>
                    <button type="button" data-bs-target="#mainCarouselDesktop" data-bs-slide-to="1" aria-label="Vencedor da jornada"></button>
                    <button type="button" data-bs-target="#mainCarouselDesktop" data-bs-slide-to="2" aria-label="Joker da jornada"></button>
                  </div>
                <div class="carousel-inner h-100">
                    <div class="carousel-item active h-100">
                        <img [src]="'https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/home-hero%2Ftop_3.jpg?alt=media&token=94408871-07f8-4b09-845a-f5fcd6b9bb7f'"
                            class="d-block w-100 h-100" alt="Top 3">
                    </div>
                    <div class="carousel-item h-100">
                        <img [src]="'https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/home-hero%2Fvencedor_jornada.jpg?alt=media&token=a8762725-c33e-4ede-bdc2-e4692f44036a'"
                            class="d-block w-100 h-100" alt="Vencedor da jornada">
                    </div>
                    <div class="carousel-item h-100">
                        <img [src]="'https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/home-hero%2Fjoker_jornada.jpg?alt=media&token=9f461ec5-07c8-41fd-87d4-419881592121'"
                            class="d-block w-100 h-100" alt="Joker da jornada">
                    </div>

                </div>
            </div>

            <div id="mainCarouselMobile" class="carousel slide carousel-fade main-banner-content mobile" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active h-100">
                        <img [src]="'https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/home-hero%2Ftop_3_mobile.jpg?alt=media&token=5308dfbf-4572-4cc1-9059-cac6aa90b30a'"
                            class="d-block w-100 h-100" alt="Top 3">
                    </div>
                    <div class="carousel-item h-100">
                        <img [src]="'https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/home-hero%2Fvencedor_jornada_mobile.jpg?alt=media&token=5614a25e-356f-45c8-ab12-e0a0b6c0363c'"
                            class="d-block w-100 h-100" alt="Vencedor da jornada">
                    </div>
                    <div class="carousel-item h-100">
                        <img [src]="'https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/home-hero%2Fjoker_jornada_mobile.jpg?alt=media&token=b7ce151b-6f21-4ca2-ad74-34df02c21474'"
                            class="d-block w-100 h-100" alt="Joker da jornada">
                    </div>

                </div>
            </div>

            <!--<div class="container-fluid">
                <div class="main-banner-content">
                    <!-<span class="sub-title">Lionel</span>
                    <h1>Mason</h1>->
                    
                </div>
            </div>-->
        </div>
    </div>
    <div class="banner-footer-content">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-5 col-sm-12">
                    <a href="mailto:geral@fantasypremiermcn.com" class="email-link">geral@fantasypremiermcn.com</a>
                </div>

                <div class="col-lg-6 col-md-7 col-sm-8" *ngIf="tournaments">
                    <ul class="social">
                        <!--<li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-twitter-1"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-instagram-1"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-linkedin-1"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-youtube-1"></i></a></li>-->
                        <li *ngFor="let tour of tournaments; let i = index;"><a routerLink="/competicoes/{{tour.slug}}">{{tour.title}}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>