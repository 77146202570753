import { Injectable } from '@angular/core';
import { firestore } from '../../environments/environment';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { collection, addDoc, getDocs, getDoc, getFirestore, setDoc, doc, updateDoc, query, orderBy, onSnapshot, where, limit } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor() { }

  async getTournaments(status?: boolean) {
    return new Promise(async (resolve, reject) => {
      if (status) {
        const querySnapshot = await getDocs(query(collection(firestore, 'competitions'), where("active", "==", status)));
        let tournaments = [];
        querySnapshot.forEach((doc) => {
          tournaments.push(doc.data());
        });

        return resolve(tournaments);
      } else {
        const querySnapshot = await getDocs(collection(firestore, 'competitions'));
        let tournaments = [];
        querySnapshot.forEach((doc) => {
          tournaments.push(doc.data());
        });

        return resolve(tournaments);
      }

    })

  }

  async getTournament(id: string, total?: number) {
    return new Promise(async (resolve, reject) => {
      if (total) {
        const q = await query((collection(firestore, id)), orderBy("total_points", "desc"), limit(total));
        onSnapshot(q, (snapshot) => {
          let tabela = [];
          snapshot.docs.forEach(doc => {
            tabela.push(doc.data())
          })
          return resolve(tabela);
        })
      } else {
        const querySnapshot = await getDocs(collection(firestore, id));
        let tabela = [];
        querySnapshot.forEach((doc) => {
          tabela.push(doc.data())
        })
        return resolve(tabela);
      }
    })
  }

  async updateTournamentPlayer(id: string, data: any, playerId?: string) {
    //await setDoc(collection(firestore, id), data);
    await addDoc(collection(firestore, id), data)
  }

  async updatePlayer(playerId: string) {
    await setDoc(doc(firestore, playerId), {
      player: "Los Angeles",
      team: "CA",
    });
  }

  async setPlayer(data: any) {
    await addDoc(collection(firestore, 'players'), data)

    //await setDoc(collection(firestore, 'players'),data)
  }

  async setTournamentStructure(id: string, data: any, docId?: string) {
    if (docId) {
      await updateDoc(doc(firestore, id, docId), data)

    } else {
      await addDoc(collection(firestore, id), data)

    }
  }

  getTop10Tournament(id: string) {
    return new Promise(async (resolve, reject) => {
      const q = await query((collection(firestore, id)), orderBy("total_points", "desc"));
      onSnapshot(q, (snapshot) => {
        let players = [];
        snapshot.docs.forEach(doc => {
          players.push(doc.data())
        })
        return resolve(players);

      })
    })
  }

  getTournamentInfo(id: string) {
    return new Promise(async (resolve, reject) => {
      const q = await query((collection(firestore, id)), where("isActive", "==", true), limit(1));
      onSnapshot(q, (snapshot) => {
        let groups = [];
        snapshot.docs.forEach(doc => {
          groups.push(doc.data())
        })
        return resolve(groups);

      })
    })
  }

  getTournamentUpcomingGames(id: string, week: number) {
    return new Promise(async (resolve, reject) => {
      const querySnapshot = await getDoc((doc(firestore, id, `week${week}`)))
      return resolve(querySnapshot.data())
    })
  }

  async getAllTeams() {
    return new Promise(async (resolve, reject) => {
      const querySnapshot = await getDocs(collection(firestore, 'players'));
      let players = [];
      querySnapshot.forEach((doc) => {
        players.push(doc.data());
      });
      return resolve(players);
    })
  }

  getProfilePic(id: string) {
    let team = id.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[&\/\\#,+()$~%.'":*?!<>{}]/g, "").replace(/[-_]/g, " ").trim();
    return new Promise((resolve, reject) => {
      // Create a reference to the file we want to download
      const storage = getStorage();
      const starsRef = ref(storage, `teams/${team}.jpg`);

      // Get the download URL
      getDownloadURL(starsRef)
        .then((url) => {
          // Insert url into an <img> tag to "download"
          return resolve(url);
        })
        .catch((error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          let url = '';
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              url = "https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/teams%2Favatar-m-0.png";
              break;
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              url = "https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/teams%2Favatar-m-0.png";

              break;
            case 'storage/canceled':
              // User canceled the upload
              url = "https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/teams%2Favatar-m-0.png";

              break;

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              url = "https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/teams%2Favatar-m-0.png";

              break;
          }

          return reject(url);
        });
    })
  }

  getListByGeneralResults(id: string, search: string, totalResults?: number) {
    return new Promise(async (resolve, reject) => {
      if (totalResults) {
        const q = await query((collection(firestore, id)), orderBy(search, "desc"), limit(totalResults));
        onSnapshot(q, (snapshot) => {
          let tabela = [];
          snapshot.docs.forEach(doc => {
            tabela.push(doc.data())
          })
          return resolve(tabela);
        })
      } else {
        const q = await query((collection(firestore, id)), orderBy(search, "desc"));
        onSnapshot(q, (snapshot) => {
          let tabela = [];
          snapshot.docs.forEach(doc => {
            tabela.push(doc.data())
          })
          return resolve(tabela);
        })
      }
    })
  }
}
