import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/shared/database.service';
import { ExportExcelService } from 'src/app/shared/export-excel.service';

@Component({
  selector: 'app-tournaments',
  templateUrl: './tournaments.component.html',
  styleUrls: ['./tournaments.component.scss']
})
export class TournamentsComponent implements OnInit {
  tournaments: any;
  activeTournaments: any;
  constructor(private db: DatabaseService) { }

  ngOnInit(): void {
    this.db.getTournaments().then((tournaments => {
      this.tournaments = tournaments;
      this.activeTournaments = this.tournaments.filter(t => t.active)
    }))
  }

}
