import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-next-match',
  templateUrl: './next-match.component.html',
  styleUrls: ['./next-match.component.scss']
})
export class NextMatchComponent implements OnInit {

  hours:any;
  minutes: any;
  seconds: any;
  days: any;

  constructor() { }

  ngOnInit(): void {
	  setInterval(() => { this.makeTimer(); }, 0);

  }



  // Count Time 
	makeTimer() {
		let endDate:any = this.getNextFriday();			
		let endTime:any = (Date.parse(endDate)) / 1000;
		let nowDate:any = new Date();
		let nowTime:any = (Date.parse(nowDate) / 1000);
		let timeLeft:any = endTime - nowTime;
		this.days = Math.floor(timeLeft / 86400); 
		this.hours = Math.floor((timeLeft - (this.days * 86400)) / 3600);
		this.minutes = Math.floor((timeLeft - (this.days * 86400) - (this.hours * 3600 )) / 60);
		this.seconds = Math.floor((timeLeft - (this.days * 86400) - (this.hours * 3600) - (this.minutes * 60)));
		if (this.hours < "10") { this.hours = "0" + this.hours; }
		if (this.minutes < "10") { this.minutes = "0" + this.minutes; }
		if (this.seconds < "10") { this.seconds = "0" + this.seconds; }
		/*$("#days").html(days + "<span>Days</span>");
		$("#hours").html(hours + "<span>Hours</span>");
		$("#minutes").html(minutes + "<span>Minutes</span>");
		$("#seconds").html(seconds + "<span>Seconds</span>");*/
	}

	
	getNextFriday(date = new Date()) {
    date.setHours(0,0,0,0);
		const dateCopy = new Date(date.getTime());

		const nextFriday = new Date(
			dateCopy.setDate(
				dateCopy.getDate() + ((7 - dateCopy.getDay() + 5) % 7 || 7),
			),
		);
		return nextFriday;
	}


}
