<section class="tournament-details-area pt-100">
    <div class="container-fluid">
        <div class="row">
            <!--<div class="col-md-12">
                <div class="tournament-details-header">
                    <h3>COMPETIÇÕES</h3>
                </div>
            </div>-->
            <div class="col-md-12 text-center p-0" *ngFor="let t of activeTournaments; let i = index;">
                <a routerLink="/competicoes/{{t.slug}}" [class.active]="t.active" [class.inactive]="!t.active">
                    <img src="../../../../assets/img/tournaments/{{t.image}}" class="img-fluid" alt="">
                </a>
            </div>
        </div>
    </div>
</section>