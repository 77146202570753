<app-main-banner></app-main-banner>

<!--<app-next-match></app-next-match>-->

<app-upcoming-matches></app-upcoming-matches>

<app-partners></app-partners>


<!--<app-products></app-products>

<app-gallery></app-gallery>

<app-matches-highlights></app-matches-highlights>

<app-subscribe></app-subscribe>

<app-blog></app-blog>-->