<section class="rules-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12 offset-lg-2">
                <div class="rules-details-header">
                    <h2>POLÍTICA DE PRIVACIDADE</h2>
                    <!--<div class="entry-meta">
                        <ul>
                            <li>
                                <i class='flaticon-categories'></i>
                                <span>Category:</span>
                                <a href="#">Football</a>
                            </li>
                            <li>
                                <i class='flaticon-man'></i>
                                <span>Posted By:</span>
                                <a href="#">Recall Smith</a>
                            </li>
                            <li>
                                <i class='flaticon-calendar'></i>
                                <span>Posted On:</span>
                                <a href="#">01/14/2020</a>
                            </li>
                        </ul>
                    </div>-->
                    
                </div>

                <div class="rules-details-desc">
                    <div class="article-content">
                        <p>A Fantasy Premier League MCN (“FPLMCN”) preocupa-se com a sua privacidade e compromete-se a
                            proteger os seus dados pessoais. Esta “Política de Privacidade e Proteção de Dados” define os
                            termos pelos quais os dados pessoais que fornece são utilizados e mantidos em segurança pela
                            FPLMCN. Esta “Política” aplica-se a todos os casos em que recolhemos os seus dados pessoais
                            (incluindo quando utiliza o nosso website e/ou outras plataformas digitais da FPLMCN por isso,
                            pedimos-lhe para que leia atentamente este documento.</p>
                        <p>Se por alguma razão não aceitar a nossa “Política de Privacidade e Proteção de Dados” por favor
                            pare imediatamente de utilizar este website.</p>
                        <h4>1. Sobre nós</h4>
                        <p>A Fantasy Premier League MCN (Marco de Canaveses) é uma organização que visa promover o
                            entretenimento da chamada plataforma fantasy premier league. Esta organização, é constituída
                            por um grupo selecionado de pessoas que competirão entre si na plataforma digital da FPL.
                        </p>
                        <p>Caso deseje saber mais sobre o mesmo grupo, pode contactar-nos através do email <a
                                href="mailto:geral@fantasypremiermcn.com">geral@fantasypremiermcn.com</a></p>

                        <h4>2. Sobre a nossa Política de Privacidade e Proteção de Dados</h4>
                        <p>Esta “Política de Privacidade e Proteção de Dados” explica quais são os dados pessoais que
                            podemos recolher, quais as suas finalidades e as medidas que tomamos para garantir que sejam
                            mantidos seguros. Também explicamos os direitos em relação aos seus dados pessoais e como
                            contactar-nos.</p>
                        <p>Por favor, note que o nosso website e outras plataformas digitais da FPLMCN podem conter
                            links para websites/plataformas digitais de terceiros que são fornecidos para sua
                            conveniência. A FPLMCN é apenas responsável pelas práticas de privacidade, proteção de dados
                            e segurança dos nossos próprios websites/plataformas digitais, pelo que recomendamos que
                            verifique as políticas e procedimentos de privacidade e segurança de cada um dos outros
                            websites/plataformas digitais que visite.</p>

                        <h4>3. Como nos contactar sobre os seus dados pessoais ou sobre esta "Política de Privacidade e
                            Proteção de Dados"</h4>
                        <p>Estamos abertos a quaisquer questões, comentários ou recomendações acerca da nossa “Política
                            de Privacidade e Proteção de Dados”. Se tiver alguma questão quanto à nossa forma de
                            recolher, armazenar ou de utilizar dos seus dados pessoais, por favor contacte-nos através
                            das seguintes formas:</p>
                        <p><strong>E-mail: <a
                                    href="mailto:geral@fantasypremiermcn.com">geral@fantasypremiermcn.com</a></strong>
                        </p>


                        <h4>4. Dados pessoais: O que são, o que recolhemos e como utilizamos</h4>
                        <p>Dados pessoais são informações específicas sobre características pessoais ou factuais
                            relacionadas com um indivíduo que pode ser identificado a partir desses dados. Isso inclui,
                            entre outras, informações como o nome, endereço, número de telefone e data de nascimento.
                        </p>
                        <p>As informações que não permitem a sua identificação ou que não podem ser diretamente ligadas
                            à sua identidade não são consideradas dados pessoais.</p>
                        <p>Todos os dados pessoais que recolhemos são registados, tratados e protegidos por nós de
                            acordo com a legislação de proteção de dados nacional e europeia (cfr. Regulamento (UE)
                            2016/679 do Parlamento Europeu e do Conselho, de 27 de Abril de 2016) em vigor. Além disso,
                            existe a possibilidade de complementar as informações coletadas com dados obtidos de outras
                            empresas, que nos são fornecidos no âmbito da celebração de contratos ou que recebemos de
                            outras organizações, por exemplo, dos nossos patrocinadores ou parceiros.</p>
                        <p>Em termos gerais, usamos os seus dados para os seguintes fins:</p>
                        <ul class="features-list">
                            <li>Divulgar todas e quaisquer competições ou outras ofertas/promoções relativamente às
                                quais tenha expresso o seu interesse</li>
                            <li>Contactar no sentido de informar sobre a indisponibilidade de quaisquer produtos ou
                                serviços que tenha encomendado ou pedido</li>
                            <li>Para fins de verificação e prevenção de fraudes</li>
                            <li>Para fins de manutenção de registos</li>
                            <li>Para rastrear a sua atividade nos nossos website/plataformas digitais</li>
                            <li>Criar o seu perfil individual (para que possamos entender e respeitar as suas
                                preferências)</li>
                            <li>Personalizar e melhorar a sua experiência nos nossos website/plataformas digitais</li>
                            <li>Personalizar e adequar qualquer comunicação que lhe possamos enviar</li>
                            <li>Para propósitos de perfil, com vista a personalizar e/ou adequar qualquer comunicação de
                                marketing que consinta receber.</li>
                        </ul>

                        <p>Quando lhe fornecemos produtos ou serviços, podemos recolher e guardar qualquer dado pessoal
                            que nos faculte. Podemos manter registos do seu nome, endereço, endereço de e-mail, número
                            de telefone e/ou detalhes do cartão de pagamento utilizado.</p>
                        <p>Quando se regista para receber as nossas comunicações de marketing (e/ou dos nossos
                            patrocinadores e parceiros), quando entra num dos nossos passatempos, quando preenche os
                            nossos formulários (seja online ou offline) ou, por qualquer outra forma, quando nos fornece
                            os seus dados pessoais, podemos recolher e armazenar qualquer dado pessoal fornecido e
                            usá-lo para personalizar e melhorar a sua experiência nas nossas plataformas digitais,
                            fornecer produtos e serviços solicitados ou realizar perfis e pesquisas de mercado.</p>
                        <p>Quando interage com as nossas plataformas digitais também recolhemos automaticamente todos os
                            dados abaixo indicados sobre a sua visita. Esta recolha é feita principalmente para nos
                            ajudar a entender melhor a forma como os nossos utilizadores utilizam as nossas plataformas
                            digitais de modo a permitir-nos criar um melhor conteúdo e comunicações mais relevantes:</p>
                        <ul class="features-list">
                            <li>Como acedeu à nossa plataforma digital e ao endereço do protocolo de internet (IP) usado
                            </li>
                            <li>Tipo de navegador, versões e plug-ins e sistema operacional</li>
                            <li>O caminho percorrido pela nossa plataforma digital, incluindo quais links acedidos e
                                todas as pesquisas feitas, quanto tempo permaneceu numa página e outras informações de
                                interação da página</li>
                            <li>Quais as mensagens pop-up ou push que pode ter visto e respondido</li>
                            <li>Dados recolhidos a partir de qualquer formulário preenchido</li>
                        </ul>

                        <p>A partir do endereço do IP utilizado para aceder às nossas plataformas digitais, podemos
                            concluir qual o seu país de localização e podemos analisar qual a atividade que determinou a
                            execução de operações específicas nas nossas plataformas digitais (por exemplo, fazer o
                            download da aplicação).</p>


                        <h4>6. Divulgação dos seus dados pessoais</h4>
                        <p>De modo a promover os nossos produtos e serviços ou para cumprir contratos, poderemos ter
                            necessidade de designar uma outra organização que, em nosso nome, trate os seus dados
                            pessoais. Estas operações podem incluir, por exemplo, organizações de processamento de
                            pagamentos, organizações de entrega, empresas de prevenção e rastreio de fraude e gestão de
                            risco de crédito ou empresas de recolha e distribuição postal.</p>
                        <p>Podemos, de igual modo, compartilhar os seus dados com terceiros:</p>
                        <ul class="features-list">
                            <li>se existir um dever legal ou regulamentar para o fazer</li>
                            <li>se for necessário fazê-lo para fazer valer os nossos “Termos e Condições” ou outros
                                direitos contratuais</li>
                            <li>para auxiliar legalmente os serviços de polícia ou de segurança para a prevenção e
                                deteção de crime ou atividade terrorista</li>
                        </ul>

                        <p>Podemos partilhar os seus dados pessoais com os nossos patrocinadores e parceiros, que foram
                            cuidadosamente selecionados e que podem ocasionalmente mudar. No entanto, só partilharemos
                            os dados se tiver consentido em receber marketing relacionado com os nossos patrocinadores e
                            parceiros ou se uma das condições do parágrafo acima se aplicar.</p>
                        <p>Algumas das organizações às quais podemos divulgar as suas informações pessoais estão
                            situadas fora de Portugal e da União Europeia, em países que podem não ter leis que protejam
                            os direitos de privacidade tão amplamente como em Portugal. Se transferirmos os seus dados
                            pessoais para outros territórios, tomaremos as medidas adequadas para garantir que estes
                            sejam protegidos de acordo com esta “Política de Privacidade e Proteção de Dados”.</p>
                        <p>Em qualquer caso de divulgação dos seus dados a terceiros, garantimos que as suas informações
                            são adequadamente protegidas.</p>


                        <h4>7. Cookies: o que são e como as utilizamos</h4>
                        <p>Nos termos da Lei nº 41/2004, de 18 de Agosto, alterada pela Lei n.º 46/2012, de 29 de
                            Agosto, é permitido, dentro dos termos legais, o armazenamento de informações e a
                            possibilidade de acesso à informação armazenada no equipamento terminal de um utilizador.
                        </p>
                        <p>Com efeito, e tal como muitos outros operadores de websites, utilizamos a tecnologia padrão
                            chamada cookies (pequenas partículas de informação que são armazenadas pelo navegador no seu
                            computador, sendo usadas para registar a navegação neste website em cada visita).</p>
                        <p>As cookies não se anexam ao seu sistema nem danificam os seus ficheiros. Grande parte dos
                            navegadores aceita automaticamente as cookies, todavia se não pretender que os seus dados
                            sejam armazenados através do uso de cookies, existe um simples procedimento na maioria dos
                            navegadores que lhe permite rejeitar ou aceitar a funcionalidade das cookies. Contudo,
                            alertámos para o facto de caso desligar a funcionalidade das cookies tal poderá limitar a
                            sua experiência no nosso website.</p>
                        <p>Mais se acrescenta que no presente website é utilizado o serviço Google Analytics para
                            registar e armazenar tipos de comportamentos dos utilizadores. O Google Analytics é um
                            serviço de análise desenvolvido pela Google Inc. (“Google”), que utiliza cookies,
                            transferindo e guardando informações num servidor nos Estados Unidos da América. Este
                            serviço somente pode usar essas informações para avaliar o seu uso no presente website,
                            criar relatórios sobre a atividade do website e fornecer outros serviços associados ao uso
                            do website e da Internet para o operador do website. O endereço IP transmitido pelo seu
                            navegador para o Google Analytics não será combinado com outros dados da Google. Conforme se
                            indicou acima, o utilizador pode impedir que as cookies sejam armazenadas, podendo, no
                            entanto, tal Acão condicionar as funcionalidades do presente website.</p>


                        <h4>8. Segurança da informação</h4>
                        <p>Como referimos anteriormente, preocupamo-nos com a segurança dos seus dados pessoais. Quando
                            navega no nosso website, usamos a tecnologia Firebase (em Firebase.google.com) com a
                            finalidade de armazenar toda a informação constada no site.</p>
                        <p>Além disso, temos procedimentos de segurança em vigor para proteger todos os nossos sistemas
                            de bases de dados (informatizados ou em papel) de situações de perda e mau uso, sendo
                            somente permitido o acesso àquelas bases de dados quando tal for absolutamente necessário,
                            sempre em cumprimento das regras quanto ao uso de dados pessoais.</p>


                        <h4>9. Utilizadores com idade inferior a 16 anos</h4>
                        <p>Se tem menos de 16 anos e se se encontra registado em áreas restritas das nossas plataformas
                            digitais, informamos que vamos recolher a sua data de nascimento e vamos armazenar esse
                            dado, bem como o seu nome e outros dados que nos tenha fornecido durante o registo e
                            navegação. Assim podemos garantir que tratamos os seus dados da maneira apropriada e
                            adequada à nossa política.</p>
                        <p>Durante o período em que tenha menos de 16 anos, não enviaremos quaisquer comunicações de
                            marketing, não permitiremos o acesso a qualquer um dos nossos quadros de mensagens da
                            plataforma digital ou compartilharemos os seus detalhes com os nossos parceiros comerciais.
                            Todavia, se se inscreveu para receber um produto ou serviço, poderemos entrar em contacto
                            consigo sobre esse assunto.</p>


                        <h4>10. Utilizadores com idades entre 13 e 15 anos</h4>
                        <p>Se tem entre 13 e 15 anos, deve primeiro informar os seus progenitores ou responsável legal
                            de que deseja registar-se nas nossas plataformas digitais e obter o seu consentimento. Deve
                            certificar-se de que os seus progenitores ou o seu tutor sabem e consentem antes de:</p>
                        <ul class="features-list">
                            <li>Nos enviar uma mensagem de correio eletrónico ou pedir que entremos em contacto pela
                                mesma via</li>
                            <li>Nos enviar qualquer informação ou dado pessoal</li>
                            <li>Entrar em qualquer competição ou passatempo que requeira dados pessoais ou ofereça um
                                prémio</li>
                        </ul>
                        <p>Se é o progenitor ou o tutor de um utilizador das nossas plataformas digitais com idade
                            compreendida entre os 13 e 15 anos, informarmos que não procuramos o seu consentimento
                            direto para o registo do menor, mas pedimos que o informe e que aquele obtenha o seu
                            consentimento antes de se registar e/ ou previamente realizar qualquer uma das atividades
                            acima enumeradas.</p>

                        <h4>11. Utilizadores com idade inferior a 13 anos</h4>
                        <p>Se tem menos de 13 anos, só poderá usar as áreas restritas das nossas plataformas digitais se
                            os seus progenitores ou tutor previamente consentirem.</p>
                        <p>Se tem menos de 13 anos e quer registar-se no nosso website, deve-nos informar do seu nome,
                            endereço de e-mail, país e data de nascimento. O nosso sistema solicitará o nome e o
                            endereço de e-mail dos seus progenitores ou do seu tutor. Enviaremos um e-mail para que eles
                            estejam cientes do seu pedido e pediremos o consentimento deles, que terão que confirmar que
                            têm poderes para prestar esse consentimento.</p>
                        <p>Precisamos deste consentimento ou recusa no prazo de 7 (sete) dias ou então assumiremos que o
                            consentimento não é concedido. Este consentimento pode ser revogado a qualquer momento.</p>
                        <p>Mesmo que os seus progenitores ou tutor consintam com o seu registo, é também necessário que
                            os informe e obtenha o seu consentimento prévio à realização de qualquer uma das seguintes
                            operações:</p>
                        <ul class="features-list">
                            <li>Envio de mensagem de correio eletrónico ou pedido para que entremos em contacto pela
                                mesma via</li>
                            <li>Envio de qualquer informação ou dado pessoal</li>
                            <li>Participação em qualquer competição ou passatempo que requeira dados pessoais ou ofereça
                                um prémio</li>
                        </ul>
                        <p>Os progenitores ou tutor de um utilizador das nossas plataformas digitais com idade inferior
                            a 13 anos, são por este meio informados que aqueles podem aceder e usar áreas irrestritas,
                            mas que é necessário o seu consentimento direto caso aqueles utilizadores pretenderem ter
                            acesso a áreas restritas.</p>
                        <p>Se o utilizador inserir uma data de nascimento indicando que tem menos de 13 anos no momento
                            do registo, o nosso sistema pedirá que o utilizador forneça o seu nome e endereço de e-mail
                            para que possamos entrar em contacto com vista a obter o consentimento dos progenitores ou
                            do tutor.</p>
                        <p>O processo de registo não pode prosseguir sem estes dados. Se não recebermos resposta dentro
                            de 7 (sete) dias, assumiremos que o consentimento é recusado. Em caso de consentimento,
                            concluiremos o processo de registo. A recusa ou consentimento será comunicado ao utilizador
                            através de e-mail.</p>
                        <p>Ressalve-se que a FPLMCN não se responsabiliza quanto à eventual prestação de falsas
                            informações por parte do menor com idade inferior a 13 anos, designadamente, no tocante à
                            sua idade aquando do momento em que se regista no presente website.</p>

                        <h4>12. Mais sobre os seus dados: os seus direitos</h4>
                        <p>O direito de retificação ou atualização de dados pessoais pode ser exercido a qualquer
                            momento, enviando um e-mail para <a
                                href="mailto:geral@fantasypremiermcn.com">geral@fantasypremiermcn.com</a> ou alterando o
                            seu perfil no nosso website. Por favor, aquando do envio, inclua o seu nome, endereço, data
                            de nascimento e endereço de e-mail para entrar em contacto, pois isso ajuda-nos a garantir
                            que aceitamos as retificações e atualizações requeridas pela pessoa com idoneidade para tal.
                        </p>
                        <p>Atente de igual modo que pode exercer o direito à limitação do tratamento dos seus dados
                            pessoais, sem prejuízo de eventuais derrogações legalmente exigidas.</p>
                        <p>Caso tenha subscrito qualquer serviço para receber comunicações de marketing, informamos que
                            pode cancelar essa mesma inscrição, a qualquer momento, utilizando o seguinte endereço: <a
                                href="mailto:geral@fantasypremiermcn.com">geral@fantasypremiermcn.com</a>.</p>

                        <h4>13. Direito de oposição</h4>
                        <p>Caso pretenda exercer o seu direito de oposição sobre qualquer aspeto relacionado com os seus
                            dados pessoais ou sobre a presente “Política de Privacidade e Proteção de Dados”, por favor
                            escreva-nos para o endereço <a
                                href="mailto:geral@fantasypremiermcn.com">geral@fantasypremiermcn.com</a>.</p>


                        <h4>14. Alterações a esta “Política de Privacidade e Proteção de Dados”</h4>
                        <p>O nosso objetivo é atender a altos padrões de segurança e privacidade e, portanto, as nossas
                            políticas e procedimentos estão constantemente sob revisão. Podemos alterar esta “Política
                            de Privacidade e Proteção de Dados” sempre que se julgue adequado e necessário. Com efeito,
                            recomendamos a verificação periódica desta página para a leitura e análise da versão mais
                            recente.</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>