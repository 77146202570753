<section class="next-match-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="next-match-content">
                    <div class="content">
                        <div class="row align-items-center justify-content-center">
                            
                            <div class="col-lg-8 col-md-10 text-center">
                                <div id="timer" class="flex-wrap d-flex justify-content-center">
                                    <div>
                                        <span>dias</span><div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}}</div>
                                    </div>
                                    <div>
                                        <span>horas</span><div id="hours" class="align-items-center flex-column d-flex justify-content-center">{{hours}}</div>
                                    </div>
                                    <div>
                                        <span>minutos</span><div id="minutes" class="align-items-center flex-column d-flex justify-content-center">{{minutes}}</div>
                                    </div>
                                    <div>
                                        <span>segundos</span><div id="seconds" class="align-items-center flex-column d-flex justify-content-center">{{seconds}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-10 text-center">
                                <h2>Próxima Jornada</h2>
                                <!--<span class="sub-title">Premiere League - 20 April, 2020</span>-->
                            </div>
                        </div>
                    </div>
                    <!--<div class="shape1"><img src="assets/img/playing.png" alt="image"></div>-->
                </div>
            </div>
            <!--<div class="col-lg-6 col-md-12">
                <div class="next-match-image">
                    <img src="assets/img/field.jpg" alt="image">
                </div>
            </div>-->
        </div>
    </div>
</section>