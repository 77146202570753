<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12 offset-lg-2">
                <div class="blog-details-header">
                    <h3>Ogbonna blasts 'lax' virus response</h3>

                    <div class="entry-meta">
                        <ul>
                            <li>
                                <i class='flaticon-categories'></i>
                                <span>Category:</span>
                                <a href="#">Football</a>
                            </li>
                            <li>
                                <i class='flaticon-man'></i>
                                <span>Posted By:</span>
                                <a href="#">Recall Smith</a>
                            </li>
                            <li>
                                <i class='flaticon-calendar'></i>
                                <span>Posted On:</span>
                                <a href="#">01/14/2020</a>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="blog-details-desc">
                    <div class="article-content">
                        <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>

                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>

                        <ul class="wp-block-gallery columns-3">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/img1.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/img2.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/img3.jpg" alt="image">
                                </figure>
                            </li>
                        </ul>

                        <blockquote class="wp-block-quote">
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>

                            <cite>Tom Cruise</cite>
                        </blockquote>

                        <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>

                        <h3>Liverpool title will be special</h3>

                        <ul class="features-list">
                            <li><strong>Influencer size and engagement rate:</strong> Scientific skills for getting a better result</li>
                            <li><strong>Influencer industry:</strong> Communication skills to getting in touch</li>
                            <li><strong>Influencer locations:</strong> A career overview opportunity available</li>
                            <li><strong>Campaign variables:</strong> A good work environment for work</li>
                        </ul>

                        <h3>Moyes self-isolating as precaution</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='flaticon-price-tag'></i></span>

                            <a href="#">Fashion</a>,
                            <a href="#">Games</a>,
                            <a href="#">Travel</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><span>Share:</span></li>
                                <li><a href="#" class="facebook" target="_blank"><i class='flaticon-facebook-logo'></i></a></li>
                                <li><a href="#" class="twitter" target="_blank"><i class='flaticon-twitter'></i></a></li>
                                <li><a href="#" class="linkedin" target="_blank"><i class='flaticon-linkedin'></i></a></li>
                                <li><a href="#" class="instagram" target="_blank"><i class='flaticon-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="recall-post-navigation">
                        <div class="prev-link-wrapper">
                            <div class="info-prev-link-wrapper">
                                <a href="#">
                                    <span class="image-prev">
                                        <img src="assets/img/blog/img1.jpg" alt="image">
                                        <span class="post-nav-title">Prev</span>
                                    </span>
                                    <span class="prev-link-info-wrapper">
                                        <span class="prev-title">Liverpool title will be special</span>
                                        <span class="meta-wrapper">
                                            <span class="date-post">January 21, 2020</span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div class="next-link-wrapper">
                            <div class="info-next-link-wrapper">
                                <a href="#">
                                    <span class="next-link-info-wrapper">
                                        <span class="next-title">Moyes self-isolating as precaution</span>
                                        <span class="meta-wrapper">
                                            <span class="date-post">January 19, 2020</span>
                                        </span>
                                    </span>
                                    <span class="image-next">
                                        <img src="assets/img/blog/img2.jpg" alt="image">
                                        <span class="post-nav-title">Next</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="comments-area">
                        <h3 class="comments-title">2 Comments:</h3>

                        <ol class="comment-list">
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/user1.jpg" class="avatar" alt="image">
                                            <b class="fn">John Jones</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a href="#">
                                                <span>April 24, 2019 at 10:59 am</span>
                                            </a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                    </div>

                                    <div class="reply">
                                        <a href="#" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>

                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/user2.jpg" class="avatar" alt="image">
                                                    <b class="fn">Steven Smith</b>
                                                    <span class="says">says:</span>
                                                </div>
    
                                                <div class="comment-metadata">
                                                    <a href="#">
                                                        <span>April 24, 2019 at 10:59 am</span>
                                                    </a>
                                                </div>
                                            </footer>
    
                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                            </div>
    
                                            <div class="reply">
                                                <a href="#" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>

                                        <ol class="children">
                                            <li class="comment">
                                                <article class="comment-body">
                                                    <footer class="comment-meta">
                                                        <div class="comment-author vcard">
                                                            <img src="assets/img/user3.jpg" class="avatar" alt="image">
                                                            <b class="fn">Sarah Taylor</b>
                                                            <span class="says">says:</span>
                                                        </div>
            
                                                        <div class="comment-metadata">
                                                            <a href="#">
                                                                <span>April 24, 2019 at 10:59 am</span>
                                                            </a>
                                                        </div>
                                                    </footer>
            
                                                    <div class="comment-content">
                                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                    </div>
            
                                                    <div class="reply">
                                                        <a href="#" class="comment-reply-link">Reply</a>
                                                    </div>
                                                </article>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>

                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/user4.jpg" class="avatar" alt="image">
                                            <b class="fn">John Doe</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a href="#">
                                                <span>April 24, 2019 at 10:59 am</span>
                                            </a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                    </div>

                                    <div class="reply">
                                        <a href="#" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>

                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/user1.jpg" class="avatar" alt="image">
                                                    <b class="fn">James Anderson</b>
                                                    <span class="says">says:</span>
                                                </div>
    
                                                <div class="comment-metadata">
                                                    <a href="#">
                                                        <span>April 24, 2019 at 10:59 am</span>
                                                    </a>
                                                </div>
                                            </footer>
    
                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                            </div>
    
                                            <div class="reply">
                                                <a href="#" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>
                                </ol>
                            </li>
                        </ol>

                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>

                            <form class="comment-form">
                                <p class="comment-notes">
                                    <span id="email-notes">Your email address will not be published.</span>
                                    Required fields are marked 
                                    <span class="required">*</span>
                                </p>
                                <p class="comment-form-author">
                                    <label>Name <span class="required">*</span></label>
                                    <input type="text" id="author" placeholder="Your Name*" name="author" required="required">
                                </p>
                                <p class="comment-form-email">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email" id="email" placeholder="Your Email*" name="email" required="required">
                                </p>
                                <p class="comment-form-url">
                                    <label>Website</label>
                                    <input type="url" id="url" placeholder="Website" name="url">
                                </p>
                                <p class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea name="comment" id="comment" cols="45" placeholder="Your Comment..." rows="5" maxlength="65525" required="required"></textarea>
                                </p>
                                <p class="comment-form-cookies-consent">
                                    <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent">
                                    <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                                </p>
                                <p class="form-submit">
                                    <input type="submit" name="submit" id="submit" class="submit" value="Post A Comment">
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>