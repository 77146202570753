import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { HomeComponent } from './components/pages/home/home.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogComponent } from './components/layouts/blog/blog.component';
import { SubscribeComponent } from './components/layouts/subscribe/subscribe.component';
import { GalleryComponent } from './components/layouts/gallery/gallery.component';
import { PartnersComponent } from './components/layouts/partners/partners.component';
import { ProductsComponent } from './components/layouts/products/products.component';
import { MatchesHighlightsComponent } from './components/layouts/matches-highlights/matches-highlights.component';
import { UpcomingMatchesComponent } from './components/layouts/upcoming-matches/upcoming-matches.component';
import { NextMatchComponent } from './components/layouts/next-match/next-match.component';
import { MainBannerComponent } from './components/layouts/main-banner/main-banner.component';
import { TournamentsComponent } from './components/pages/tournaments/tournaments.component';
import { TeamsComponent } from './components/pages/teams/teams.component';
import { MaintenanceComponent } from './components/pages/maintenance/maintenance.component';
import { RulesComponent } from './components/pages/rules/rules.component';
import { SingleTournamentComponent } from './components/pages/single-tournament/single-tournament.component';
import { PrivacyPolicyComponent } from './components/layouts/privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    HomeComponent,
    BlogDetailsComponent,
    BlogComponent,
    SubscribeComponent,
    GalleryComponent,
    PartnersComponent,
    ProductsComponent,
    MatchesHighlightsComponent,
    UpcomingMatchesComponent,
    NextMatchComponent,
    MainBannerComponent,
    TournamentsComponent,
    TeamsComponent,
    MaintenanceComponent,
    RulesComponent,
    SingleTournamentComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
