<section class="rules-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12 offset-lg-2">
                <div class="rules-details-header">
                    <h2>REGULAMENTO FANTASY PREMIER LEAGUE</h2>
                    <h3>Liga Marco de Canaveses 2024/2025</h3>

                    <!--<div class="entry-meta">
                        <ul>
                            <li>
                                <i class='flaticon-categories'></i>
                                <span>Category:</span>
                                <a href="#">Football</a>
                            </li>
                            <li>
                                <i class='flaticon-man'></i>
                                <span>Posted By:</span>
                                <a href="#">Recall Smith</a>
                            </li>
                            <li>
                                <i class='flaticon-calendar'></i>
                                <span>Posted On:</span>
                                <a href="#">01/14/2020</a>
                            </li>
                        </ul>
                    </div>-->
                </div>

                <div class="rules-details-desc">
                    <div class="article-content">
                        <h4>Orçamento da Liga</h4>
                        <ul class="list-unstyled">
                            <li><strong>Receitas:</strong> </li>
                            <li>75 Equipas</li>
                            <li>Inscrição 100€ (Pago totalidade 15 dias antes de começar)</li>
                            <li><strong>Total:</strong> 7500€</li>
                            <li><strong>Despesas:</strong> 760€ (site incluído)</li>
                            <li><strong>Prémios:</strong> 6740€ </li>
                            <li><strong>Outras despesas:</strong> Fim da página</li>
                            <li><br></li>
                            <li><strong>Jantar:</strong> 1 semana após última jornada</li>
                        </ul>


                        <h4>Como jogar</h4>
                        <ul class="list-unstyled">
                            <li><strong>Escolha da Equipa</strong> </li>
                            <li>2 Guarda Redes</li>
                            <li>5 Defesas</li>
                            <li>5 Médios</li>
                            <li>3 Avançados</li>
                            <li><br></li>
                            <!--<li><strong>Guarda Redes:</strong> 80€</li>
                            <li><strong>Defesas:</strong> 100€ (50€ antes da 1ª jornada (05/08) e 50€ antes de começar a
                                2ª volta em Dezembro)</li>
                            <li><strong>Médios:</strong> 8000€</li>
                            <li><strong>Avançados:</strong> 20-25€ (entre 1600€ a 2000€)</li>
                            <li><strong>Montante para gastar:</strong> 20-25€ (entre 1600€ a 2000€)</li>-->
                            <li>100 milhões para gastar</li>
                            <li><br></li>
                            <li>Só se podem escolher 3 jogadores de cada equipa</li>
                        </ul>

                        <p>Dos 15 jogadores de cada equipa, apenas pontuam 11 por jornada. Assim, escolhe-se o onze
                            titular sendo obrigatório, por posição, ter no mínimo 1 redes e 3 defesas, 3 médios e 1
                            avançado. Se eventualmente algum dos titulares não for utilizado, entram os suplentes de
                            acordo com a posição e pela ordem escolhida (como explicado no capítulo “Substituições”,
                            mais à frente)</p>

                        <p>Podem transferir um jogador por jornada. Se fizerem mais do que uma substituição, perdem 4
                            pontos por cada substituição adicional. Se não fizerem nenhuma substituição, têm até 5
                            gratuitas.</p>

                        <h4>Capitão e Vice Capitão</h4>

                        <p>Todas as jornadas é escolhido um capitão e um vice capitão para a equipa. O capitão dá pontos
                            a dobrar, o vice capitão só dará pontos a dobrar, se o capitão não jogar nessa jornada.</p>



                        <h4>WILDCARD</h4>
                        <!-- <ul class="wp-block-gallery columns-4">
                            <div class="blocks-gallery-item">

                                <h5>CAPITÃO E VICE CAPITÃO</h5>
                                <p>Todas as jornadas escolhemos um capitão e um vice capitão para a nossa equipa. O
                                    capitão dá
                                    pontos a dobrar, o vice capitão só dará pontos a dobrar, se o capitão não jogar
                                    nessa jornada.</p>

                            </div>
                            <div class="blocks-gallery-item">

                                <h5>WILDCARD</h5>
                                <p>Nesta opção podemos mudar a equipa toda 1 vez por cada volta, equipa essa que após
                                    usar
                                    wildcard mantém-se.</p>

                            </div>

                            <div class="blocks-gallery-item">

                                <h5>BENCH BOOST</h5>
                                <p>Nesta opção todos os jogadores da equipa contam na pontuação.  Só podemos usar 1 vez
                                    na
                                    época.</p>

                            </div>

                            <div class="blocks-gallery-item">

                                <h5>TRIPLO CAPITÃO</h5>
                                <p>Nesta opção o capitão conta pontos a triplicar, caso não jogue o capitão passa a ser
                                    o vice
                                    capitão. Só podemos usar 1 vez na época.</p>

                            </div>

                            <div class="blocks-gallery-item">

                                <h5>FREE HIT</h5>
                                <p>Nesta opção podemos mudar a equipa toda na presente jornada, mas na próxima jornada
                                    voltamos à equipa que tínhamos. Só podemos usar 1 vez na época.</p>

                            </div>
                        </ul> -->

                        <p>Ativando a opção Wildcard, pode-se fazer substituições ilimitadas uma vez em cada volta do
                            campeonato sem perda de pontos.</p>

                        <h4>BENCH BOOST</h4>

                        <p>Nesta opção todos os jogadores (15) da equipa contam na pontuação. Só se pode utilizar uma
                            vez na época.</p>

                        <h4>TRIPLO CAPITÃO</h4>

                        <p>Nesta opção o capitão conta pontos a triplicar, caso não jogue o capitão passa a ser o vice
                            capitão. Só se pode utilizar 1 vez na época.</p>

                        <h4>FREE HIT</h4>

                        <p>Ativando o Free Hit (FH), pode-se fazer substituições ilimitadas sem perda de pontos para
                            apenas uma jornada. Ou seja, a diferença para o Wildcard é que após a utilização do Free
                            Hit, a equipa volta a ser a que estava selecionada na jornada anterior. Exemplo: se o FH for
                            utilizado na jornada 20, na jornada 21 a equipa volta a ser a que estava escolhida para a
                            jornada 19.
                            Só se pode utilizar uma vez na época.</p>

                        <blockquote class="wp-block-quote">
                            <p>SUBSTITUIÇÕES: Leiam isto mesmo com atenção, eu penso que não me esqueci de nada e
                                qualquer dúvida que tenham, é só perguntar no outro grupo. Como há alguns elementos que
                                nunca jogaram e assim, eu comprometo-me a avisar todas as jornadas para não se
                                esquecerem de fazer a equipa.</p>
                            <!-- <small>Exemplo: Se for penalizado em 8 pontos antes de começar a jornada e a minha pontuação
                                final for 70 pontos, a pontuação dessa jornada em todas as competições será de 62
                                pontos.</small> -->
                        </blockquote>

                        <p>Deve-se ordenar o banco de suplentes da esquerda para a direita de acordo com a preferência
                            na ordem de substituição caso algum jogador que esteja no onze titular não jogue nessa
                            jornada.</p>

                        <p>O guarda redes é sempre a primeira opção no banco, pois só entra se o guarda-redes titular
                            não jogar. Os restantes 3 suplentes são ordenados livremente.
                            A ordem de substituições também tem de cumprir a utilização mínima de jogadores por posição
                            (referido no capítulo “Escolha da Equipa”). Exemplo: caso seja um defesa titular não jogue
                            nessa jornada, pode ser substituído por um médio ou um avançado, caso inicialmente tenham
                            sido escolhidos mais do que o mínimo de 3 defesas. Se A equipa selecionada inicialmente
                            tiver apenas 3 defesas e um deles não for utilizado nessa jornada, terá obrigatoriamente de
                            ser substituído pelo primeiro defesa que estiver no banco para cumprir o número mínimo da
                            posição. A regra é a mesma para médios e avançados</p>

                        <blockquote class="wp-block-quote">
                            <p>NOTA: todos os pontos perdidos por jornada devido a substituições adicionais são
                                descontados em todas as competições.</p>
                            <small>Exemplo: se perder 8 pontos antes começar a jornada e a pontuação final for 70
                                pontos, a pontuação dessa jornada em todas as competições é de 62 pontos.</small>
                        </blockquote>

                        <h4>Prémios</h4>
                        <h5>Classificação Geral</h5>
                        <ul class="list-unstyled">
                            <li>1º lugar&emsp;<strong>400€</strong></li>
                            <li>2º lugar&emsp;<strong>350€</strong></li>
                            <li>3º lugar&emsp;<strong>300€</strong></li>
                            <li>4º lugar&emsp;<strong>250€</strong></li>
                            <li>5º lugar&emsp;<strong>200€</strong></li>
                            <li>6º lugar&emsp;<strong>180€</strong></li>
                            <li>7º lugar&emsp;<strong>160€</strong></li>
                            <li>8º lugar&emsp;<strong>140€</strong></li>
                            <li>9º lugar&emsp;<strong>120€</strong></li>
                            <li>10º lugar&emsp;<strong>100€</strong></li>
                            <li>11º ao 15º lugar&emsp;<strong>50€</strong> (Total 250€)</li>
                            <li>16º ao 25º lugar&emsp;<strong>30€</strong> (Total 300€)</li>
                            <li>26º ao 35º lugar&emsp;<strong>20€</strong> (Total 200€)</li>
                            <li><strong>Total final: 2950€</strong></li>
                        </ul>

                        <div class="article-footer">
                            <h2>Competições</h2>
                        </div>

                        <!-- CAMPEÃO INVERNO -->
                        <h3>Campeão Inverno</h3>

                        <p>1º classificado no fim da 19ª jornada. Em caso de empate o prémio será dividido </p>

                        <h4>Prémio</h4>
                        <ul class="list-unstyled">
                            <li><strong>100€</strong></li>
                        </ul>
                        <!-- PRÉMIO MENSAL -->
                        <h4>Prémio Mensal <small>(Para apuramento do prémio mensal e semanal, considera-se apenas os
                                pontos conquistados em cada mês e jornada respetivamente)</small></h4>
                        <ul class="list-unstyled">
                            <li>1º lugar&emsp;<strong>50€</strong></li>
                            <li>2º lugar&emsp;<strong>40€</strong></li>
                            <li>3º lugar&emsp;<strong>30€</strong></li>
                            <li>4º lugar&emsp;<strong>20€</strong></li>
                            <li>5º lugar&emsp;<strong>10€</strong></li>
                            <li><strong>Total final: 1500€</strong></li>
                        </ul>


                        <!-- PRÉMIO SEMANAL -->
                        <h4>Prémio Semanal</h4>
                        <ul class="list-unstyled">
                            <li>2º lugar&emsp;<strong>20€</strong></li>
                            <li>3º lugar&emsp;<strong>10€</strong></li>
                            <li><strong>Total final: 1140€</strong></li>
                        </ul>

                        <!-- PRÉMIO MAIOR PONTUAÇÃO ÉPOCA -->
                        <h4>Prémio maior pontuação da época</h4>
                        <ul class="list-unstyled">
                            <li><strong>10€</strong> (caso empate ganha quem está melhor na classificação no fim da
                                época)</li>
                        </ul>

                        <!-- PRÉMIO MELHOR CAPITÃO DA ÉPOCA -->
                        <h4>Prémio melhor capitão da época</h4>
                        <ul class="list-unstyled">
                            <li><strong>10€</strong> (caso empate ganha quem está melhor na classificação no fim da
                                época)</li>
                        </ul>

                        <!-- LIGA DOS CAMPEÕES  -->

                        <h3>Liga dos Campeões</h3>


                        <ul class="features-list">
                            <li>5 grupos (A-E) com 15 equipas (disputar entre jornada 2-16)</li>
                            <li>Passam as primeiras 8 equipas de cada grupo (passam 40 equipas)</li>
                        </ul>
                        <p>Critério de desempate:</p>
                        <ul class="features-list">
                            <li>Confronto direto entre equipas empatadas</li>
                            <li>Melhor classificação no campeonato</li>
                            <li>Maior número de pontos obtidos pelo capitão nas jornadas decorrentes</li>
                        </ul>

                        <h4>Sorteio via online</h4>
                        <h5>1ª jornada (2ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>15 vs 4 </li>
                            <li>10 vs 3 </li>
                            <li>14 vs 8</li>
                            <li>11 vs 5 </li>
                            <li>12 vs 7 </li>
                            <li>13 vs 2 </li>
                            <li>9 vs 6 </li>
                            <li>1 - folga </li>
                        </ul>
                        <h5>2ª jornada (3ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>2 vs 14 </li>
                            <li>6 vs 13 </li>
                            <li>7 vs 9</li>
                            <li>1 vs 10 </li>
                            <li>4 vs 11 </li>
                            <li>8 vs 15 </li>
                            <li>3 vs 5 </li>
                            <li>12 - folga </li>
                        </ul>

                        <h5>3ª jornada (4ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>4 vs 8 </li>
                            <li>13 vs 7 </li>
                            <li>15 vs 2</li>
                            <li>14 vs 6 </li>
                            <li>11 vs 3 </li>
                            <li>12 vs 10 </li>
                            <li>5 vs 1 </li>
                            <li>9 - folga </li>
                        </ul>
                        <h5>4ª jornada (5ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>11 vs 8 </li>
                            <li>3 vs 1 </li>
                            <li>6 vs 15</li>
                            <li>2 vs 4 </li>
                            <li>7 vs 14 </li>
                            <li>9 vs 10 </li>
                            <li>5 vs 12 </li>
                            <li>13 - folga </li>
                        </ul>

                        <h5>5ª jornada (6ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>15 vs 7 </li>
                            <li>4 vs 6 </li>
                            <li>5 vs 9</li>
                            <li>11 vs 1 </li>
                            <li>8 vs 2 </li>
                            <li>13 vs 10 </li>
                            <li>12 vs 3 </li>
                            <li>14 - folga </li>
                        </ul>

                        <h5>6ª jornada (7ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>2 vs 11 </li>
                            <li>5 vs 13 </li>
                            <li>1 vs 12</li>
                            <li>10 vs 14 </li>
                            <li>6 vs 8 </li>
                            <li>7 vs 4 </li>
                            <li>9 vs 3 </li>
                            <li>15 - folga </li>
                        </ul>

                        <h5>7ª jornada (8ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>12 vs 11 </li>
                            <li>14 vs 5 </li>
                            <li>3 vs 13</li>
                            <li>2 vs 6 </li>
                            <li>19 vs 15 </li>
                            <li>8 vs 7 </li>
                            <li>1 vs 9 </li>
                            <li>4 - folga </li>
                        </ul>

                        <h5>8ª jornada (9ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>1 vs 13 </li>
                            <li>9 vs 12 </li>
                            <li>7 vs 2</li>
                            <li>11 vs 6 </li>
                            <li>10 vs 4 </li>
                            <li>5 vs 15 </li>
                            <li>3 vs 14 </li>
                            <li>8 - folga </li>
                        </ul>

                        <h5>9ª jornada (10ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>14 vs 1 </li>
                            <li>13 vs 12 </li>
                            <li>6 vs 7</li>
                            <li>15 vs 3 </li>
                            <li>4 vs 5 </li>
                            <li>8 vs 10 </li>
                            <li>11 vs 9 </li>
                            <li>2 - folga </li>
                        </ul>
                        <h5>10ª jornada (11ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>9 vs 13 </li>
                            <li>3 vs 4 </li>
                            <li>12 vs 14</li>
                            <li>7 vs 11 </li>
                            <li>1 vs 15 </li>
                            <li>10 vs 2 </li>
                            <li>5 vs 8 </li>
                            <li>6 - folga </li>
                        </ul>
                        <h5>11ª jornada (12ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>14 vs 9 </li>
                            <li>2 vs 5 </li>
                            <li>4 vs 1</li>
                            <li>13 vs 11 </li>
                            <li>15 vs 12 </li>
                            <li>8 vs 3 </li>
                            <li>6 vs 10 </li>
                            <li>7 - folga </li>
                        </ul>
                        <h5>12ª jornada (13ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>13 vs 14 </li>
                            <li>9 vs 15 </li>
                            <li>1 vs 8</li>
                            <li>3 vs 2 </li>
                            <li>5 vs 6 </li>
                            <li>12 vs 4 </li>
                            <li>10 vs 7 </li>
                            <li>11 - folga </li>
                        </ul>
                        <h5>13ª jornada (14ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>8 vs 12 </li>
                            <li>6 vs 3 </li>
                            <li>14 vs 11</li>
                            <li>2 vs 1 </li>
                            <li>15 vs 13 </li>
                            <li>4 vs 9 </li>
                            <li>7 vs 5 </li>
                            <li>10 - folga </li>
                        </ul>
                        <h5>14ª jornada (15ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>14 vs 15 </li>
                            <li>9 vs 8 </li>
                            <li>12 vs 2</li>
                            <li>11 vs 10 </li>
                            <li>3 vs 7 </li>
                            <li>13 vs 4 </li>
                            <li></li>
                            <li>5 - folga </li>
                        </ul>
                        <h5>15ª jornada (16ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>10 vs 5 </li>
                            <li>8 vs 13 </li>
                            <li>6 vs 12</li>
                            <li>2 vs 9 </li>
                            <li>4 vs 14 </li>
                            <li>7 vs 1 </li>
                            <li>15 vs 11 </li>
                            <li>3 - folga </li>
                        </ul>

                        <!-- <p>Os 5º e 6º classificados descem para Liga Europa</p>
                        <p>Dos 7º aos 10º classificados descem para a Conference League</p> -->

                        <h5>2ª fase de grupos</h5>

                        <h5>1ª jornada (17ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>2 vs 5 </li>
                            <li>8 vs 3 </li>
                            <li>6 vs 4</li>
                            <li>1 vs 7 </li>
                        </ul>
                        <h5>2ª jornada (18ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>8 vs 6 </li>
                            <li>4 vs 2 </li>
                            <li>5 vs 1</li>
                            <li>3 vs 7 </li>
                        </ul>

                        <h5>3ª jornada (19ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>2 vs 8 </li>
                            <li>6 vs 7 </li>
                            <li>1 vs 3</li>
                            <li>5 vs 4 </li>
                        </ul>

                        <h5>4ª jornada (20ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>4 vs 1 </li>
                            <li>7 vs 2 </li>
                            <li>3 vs 6</li>
                            <li>8 vs 5 </li>
                        </ul>
                        <h5>5ª jornada (21ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>2 vs 3 </li>
                            <li>5 vs 7 </li>
                            <li>6 vs 1</li>
                            <li>4 vs 8 </li>
                        </ul>
                        <h5>6ª jornada (22ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>6 vs 2 </li>
                            <li>1 vs 8 </li>
                            <li>3 vs 5</li>
                            <li>7 vs 4 </li>
                        </ul>
                        <h5>7ª jornada (23ª jornada fantasy)</h5>
                        <ul class="list-unstyled">
                            <li>5 vs 6 </li>
                            <li>2 vs 1 </li>
                            <li>4 vs 3</li>
                            <li>8 vs 7 </li>
                        </ul>

                        <ul class="list-unstyled">
                            <li>
                                Oitavos (Fase a eliminar a duas mãos jornadas premier 26 e 28)</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Jogo 1: 1º grupo A vs 3º grupo B</li>
                                    <li>Jogo 2: 2º grupo C vs 2º grupo D</li>
                                    <li>Jogo 3: 1º grupo C vs 3º grupo D</li>
                                    <li>Jogo 4: 2º grupo A vs 2º grupo E</li>
                                    <li>Jogo 5: 1º grupo D vs 3º grupo E</li>
                                    <li>Jogo 6: 2º grupo B vs 3º grupo A</li>
                                    <li>Jogo 7: 1º grupo B VS 4º melhor do campeonato</li>
                                    <li>Jogo 8: 1º grupo E vs 3º grupo C</li>
                                </ul>
                            </li>
                            <li>Quartos (Fase a eliminar a duas mãos na jornada 30 e 32)</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Jogo 9: Vencedor jogo 1 vs vencedor jogo 2</li>
                                    <li>Jogo 10: Vencedor jogo 3 vs vencedor jogo 4</li>
                                    <li>Jogo 11: Vencedor jogo 5 vs vencedor jogo 6</li>
                                    <li>Jogo 12: vencedor jogo 7 vs vencedor jogo 8</li>
                                </ul>
                            </li>
                            <li>Meias (Fase a eliminar a duas mãos na jornada 34 e 36)</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Jogo 13: Vencedor jogo 9 vs vencedor jogo 10</li>
                                    <li>Jogo 14: Vencedor jogo 11 vs vencedor jogo 12</li>
                                </ul>
                            </li>
                            <li>Apuramento do 3º e 4º classificados decorre em simultâneo com a jornada 37</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Vencido jogo 13 vs vencido jogo 14</li>
                                </ul>
                            </li>
                            <li>Final: decorre em simultâneo com a jornada 38</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Vencedor jogo 13 vs vencedor jogo 14</li>
                                </ul>
                            </li>
                        </ul>

                        <blockquote class="wp-block-quote">
                            <p>NOTA: Fase eliminar, caso de empate:</p>
                            <ul class="list-unstyled">
                                <li>- Passa melhor classificado</li>
                                <li>- Se com mesmo pontos, passa equipa com capitão nos jogos a eliminar com mais pontos
                                </li>
                                <li>- Caso se mantenha igual, passa vice capitão com mais pontos somados nos jogos a
                                    eliminar</li>
                            </ul>
                        </blockquote>

                        <!-- <ul class="list-unstyled">
                            <li>8 grupos de 4 equipas</li>
                            <li>Grupo A: (1º grupo A; 2ºgrupo B; 3º Grupo C; 4º Grupo D)</li>
                            <li>Grupo B: (1º grupo E; 2º grupo F; 3º grupo G; 4º grupo H)</li>
                            <li>Grupo C (1º grupo B; 2º grupo C; 3º grupo D; 4º grupo A)</li>
                            <li>Grupo D (1º grupo F; 2º grupo G; 3º grupo H; 4º grupo E)</li>
                            <li>Grupo E (1º grupo C; 2º grupo D; 3º grupo A; 4º grupo B)</li>
                            <li>Grupo F (1º grupo G; 2º grupo H; 3º grupo E; 4º grupo F)</li>
                            <li>Grupo G (1º grupo D; 2º grupo A; 3º grupo B; 4º grupo D)</li>
                            <li>Grupo H (1º grupo H; 2º grupo E; 3º grupo F; 4º grupo G)</li>
                        </ul>

                        <p>Passam os primeiros dois classificados de cada grupo, os restantes descem para a Liga Europa
                            para realizarem os 16 avos de final com os outros 16 que desceram na primeira fase</p>

                        <p>1ª e 4ª Jornada (decorre em simultâneo com a 11ª e 14ª jornadas da Premier League 2022/2023)
                        </p>
                        <p>2ª jornada e 5ª jornada (decorre em simultâneo com a 12ª e 15ª jornadas da Premier League
                            2022/2023)</p>
                        <p>3º jornada e 6ª jornada (decorre em simultâneo com a 13ª e 16ª jornadas da Premier League
                            2022/2023)</p>
                        <br>


                        <ul class="list-unstyled">
                            <li>
                                Oitavos (Fase a eliminar a duas mãos): decorre em simultâneo com a 26ª e 28ª jornadas da
                                Premier League 2022/2023</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Jogo 1: 1º grupo A vs 2º grupo B</li>
                                    <li>Jogo 2: 1º grupo C vs 2º grupo D</li>
                                    <li>Jogo 3: 1º grupo E vs 2º grupo F</li>
                                    <li>Jogo 4: 1º grupo G vs 2º grupo H</li>
                                    <li>Jogo 5: 1º grupo B vs 2º grupo A</li>
                                    <li>Jogo 6: 1º grupo D vs 2º grupo C</li>
                                    <li>Jogo 7: 1º grupo F VS 2º grupo E</li>
                                    <li>Jogo 8: 1º grupo H vs 2º grupo G</li>
                                </ul>
                            </li>
                            <li>Quartos (Fase a eliminar a duas mãos): decorre em simultâneo com a 30ª e 32ª jornadas da
                                Premier League 2022/2023</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Jogo 9: Vencedor jogo 1 vs vencedor jogo 2</li>
                                    <li>Jogo 10: Vencedor jogo 3 vs vencedor jogo 4</li>
                                    <li>Jogo 11: Vencedor jogo 5 vs vencedor jogo 6</li>
                                    <li>Jogo 12: vencedor jogo 7 vs vencedor jogo 8</li>
                                </ul>
                            </li>
                            <li>Meias (Fase a eliminar a duas mãos): decorre em simultâneo com a 34ª e 36ª jornadas da
                                Premier League 2022/2023</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Jogo 13: Vencedor jogo 9 vs vencedor jogo 10</li>
                                    <li>Jogo 14: Vencedor jogo 11 vs vencedor jogo 12</li>
                                </ul>
                            </li>
                            <li>Apuramento do 3º e 4º classificados: decorre em simultâneo com a 37ª jornada da Premier
                                League 2022/2023</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Vencido jogo 13 vs vencido jogo 14</li>
                                </ul>
                            </li>
                            <li>Final: decorre em simultâneo com a 38ª jornada da Premier League 2022/2023</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Vencedor jogo 13 vs vencedor jogo 14</li>
                                </ul>
                            </li>
                        </ul>

                        <blockquote class="wp-block-quote">
                            <p>NOTA: Fase eliminar, caso de empate:</p>
                            <ul class="list-unstyled">
                                <li>- Passa melhor classificado</li>
                                <li>- Se com mesmo pontos, passa equipa com capitão nos jogos a eliminar com mais pontos
                                </li>
                                <li>- Caso se mantenha igual, passa vice capitão com mais pontos somados nos jogos a
                                    eliminar</li>
                            </ul>
                        </blockquote> -->

                        <h4>Prémios</h4>
                        <ul class="list-unstyled">
                            <li>1º lugar&emsp;<strong>200€</strong></li>
                            <li>2º lugar&emsp;<strong>150€</strong></li>
                            <li>3º lugar&emsp;<strong>100€</strong></li>
                            <li>4º lugar&emsp;<strong>50€</strong></li>
                            <li><strong>Total 500€</strong></li>
                        </ul>


                        <!-- LIGA EUROPA -->
                        <h3>Liga Europa</h3>


                        <p>24 EQUIPAS <br> 9º ao 13º classificados da 1ª fase da Champions (exceto pior 13ª classificado
                            no campeonato que segue para a Conference League)</p>
                        <p>4 grupos 6 <br>
                            Passam os 4 primeiros (restantes vão para a Conference League)</p>

                        <p>17ª à 21ª jornada <br>
                            16 equipas – jogam entre si passam 8 melhores da Jornada 25/26</p>

                        <p>16 avos – (8 equipas da Liga Euorpa + 24 equipas da Champions) Jornada 27/28</p>

                        <p>Oitavos-29/30</p>
                        <p>Quartos- 31/32</p>
                        <p>Meias- 34/35</p>
                        <p>Final-37</p>

                        <!-- <p>Terá início após a 2ª fase da Liga dos Campeões:</p>
                        <ul class="features-list">
                            <li>5º e 6º classificados eliminados na 1ª fase vs eliminados da 2ª fase da Liga dos
                                Campeões</li>
                        </ul>
                        <p>16 Avos de Final:</p>
                        <ul class="features-list">
                            <li>Decorre em simultâneo com a 21ª e 23º jornadas <!--da Premier League 2022/2023-></li>
                        </ul>

                        <ul class="list-unstyled">
                            <li>Sorteio entre equipas arrumadas na 1ª fase contra as da 2ª fase</li>
                            <li>Oitavos (Fase a eliminar a duas mãos): decorre em simultâneo com a 25ª e 27ª jornadas
                            </li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Jogo 17: Vencedor Jogo 1 vs vencedor jogo 2</li>
                                    <li>Jogo 18: Vencedor Jogo 3 vs vencedor jogo 4</li>
                                    <li>Jogo 19: Vencedor Jogo 5 vs vencedor jogo 6</li>
                                    <li>Jogo 20: Vencedor Jogo 7 vs vencedor jogo 8</li>
                                    <li>Jogo 21: Vencedor Jogo 9 vs vencedor jogo 10</li>
                                    <li>Jogo 22: Vencedor Jogo 11 vs vencedor jogo 12</li>
                                    <li>Jogo 23: Vencedor Jogo 13 vs vencedor jogo 14</li>
                                    <li>Jogo 24: Vencedor Jogo 15 vs vencedor jogo 16</li>
                                </ul>
                            </li>
                            <li>Quartos (Fase a eliminar a duas mãos): decorre em simultâneo com a 29ª e 31ª jornadas da
                                Premier League 2022/2023</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Jogo 25: Vencedor jogo 17 vs vencedor jogo 18</li>
                                    <li>Jogo 26: Vencedor jogo 19 vs vencedor jogo 20</li>
                                    <li>Jogo 27: Vencedor jogo 21 vs vencedor jogo 22</li>
                                    <li>Jogo 28: vencedor jogo 23 vs vencedor jogo 24</li>
                                </ul>
                            </li>
                            <li>Meias (Fase a eliminar a duas mãos): decorre em simultâneo com a 33ª e 35ª jornadas</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Jogo 29: Vencedor jogo 25 vs vencedor jogo 26</li>
                                    <li>Jogo 30: Vencedor jogo 27 vs vencedor jogo 28</li>
                                </ul>
                            </li>
                            <li>Final: decorre em simultâneo com a 37ª jornada</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Vencedor jogo 29 vs vencedor jogo 30</li>
                                </ul>
                            </li>
                        </ul> -->

                        <blockquote class="wp-block-quote">
                            <p>NOTA: Fase eliminar, caso de empate:</p>
                            <ul class="list-unstyled">
                                <li>- Passa melhor classificado</li>
                                <li>- Se com mesmo pontos, passa equipa com capitão nos jogos a eliminar com mais pontos
                                </li>
                                <li>- Caso se mantenha igual, passe vice capitão com mais pontos somados nos jogos a
                                    eliminar</li>
                            </ul>
                        </blockquote>

                        <h4>Prémios</h4>
                        <ul class="list-unstyled">
                            <li>1º lugar&emsp;<strong>70€</strong></li>
                            <li>2º lugar&emsp;<strong>30€</strong></li>
                            <li><strong>Total 100€</strong></li>
                        </ul>

                        <!-- CONFERENCE LEAGUE -->

                        <h3>Conference League</h3>

                        <p>11 EQUIPAS</p>
                        <p>Campeonato entre si a 1 mão <br>
                            Passam as 8 Primeiras <br>
                            17ª à 27ª jornada
                        </p>
                        <p>8 Equipas conference VS 8 da Liga Europa (arrumaram fase de grupos da Liga Europa)</p>
                        <p>Oitavos– Jornadas 28/29</p>
                        <p> Quartos – Jornadas 30/32</p>
                        <p> Meias – Jornadas 33/34</p>
                        <p> Final - Jornada 36</p>


                        <!-- <p>Dos 7º aos 10º classificados da 1ª fase da Liga dos Campões descem para a Conference League
                        </p>

                        <ul class="list-unstyled">
                            <li>16 Avos (Fase a eliminar a duas mãos): decorre em simultâneo com a 17ª e 18ª jornadas da
                                Premier League 2022/2023</li>
                            <li>Oitavos (Fase a eliminar a duas mãos): decorre em simultâneo com a 20ª e 21ª jornadas da
                                Premier League 2022/2023</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Jogo 17: Vencedor Jogo 1 vs vencedor jogo 2</li>
                                    <li>Jogo 18: Vencedor Jogo 3 vs vencedor jogo 4</li>
                                    <li>Jogo 19: Vencedor Jogo 5 vs vencedor jogo 6</li>
                                    <li>Jogo 20: Vencedor Jogo 7 vs vencedor jogo 8</li>
                                    <li>Jogo 21: Vencedor Jogo 9 vs vencedor jogo 10</li>
                                    <li>Jogo 22: Vencedor Jogo 11 vs vencedor jogo 12</li>
                                    <li>Jogo 23: Vencedor Jogo 13 vs vencedor jogo 14</li>
                                    <li>Jogo 24: Vencedor Jogo 15 vs vencedor jogo 16</li>
                                </ul>
                            </li>
                            <li>Quartos: Oitavos (Fase a eliminar a duas mãos): decorre em simultâneo com a 23ª e 24ª
                                jornadas da Premier League 2022/2023</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Jogo 25: Vencedor jogo 17 vs vencedor jogo 18</li>
                                    <li>Jogo 26: Vencedor jogo 19 vs vencedor jogo 20</li>
                                    <li>Jogo 27: Vencedor jogo 21 vs vencedor jogo 22</li>
                                    <li>Jogo 28: vencedor jogo 23 vs vencedor jogo 24</li>
                                </ul>
                            </li>
                            <li>Meias (Fase a eliminar a duas mãos): decorre em simultâneo com a 26ª e 27ª jornadas da
                                Premier League 2022/2023</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Jogo 29: Vencedor jogo 25 vs vencedor jogo 26</li>
                                    <li>Jogo 30: Vencedor jogo 27 vs vencedor jogo 28</li>
                                </ul>
                            </li>
                            <li>Final: decorre em simultâneo com a 29ª jornada da Premier League 2022/2023</li>
                            <li>
                                <ul class="list-unstyled">
                                    <li>Vencedor jogo 29 vs vencedor jogo 30</li>
                                </ul>
                            </li>
                        </ul>

                        <blockquote class="wp-block-quote">
                            <p>NOTA: Fase eliminar, caso de empate:</p>
                            <ul class="list-unstyled">
                                <li>- Passa melhor classificado</li>
                                <li>- Se com mesmo pontos, passa equipa com capitão nos jogos a eliminar com mais pontos
                                </li>
                                <li>- Caso se mantenha igual, passe vice capitão com mais pontos somados nos jogos a
                                    eliminar</li>
                            </ul>
                        </blockquote> -->

                        <h4>Prémios</h4>
                        <ul class="list-unstyled">
                            <li><strong>20€</strong></li>
                        </ul>

                        <!-- TAÇA André Monteiro -->
                        <h3>Taça André Monteiro</h3>

                        <ul class="features-list">
                            <li>Será eliminado sempre a equipa com menos pontos no campeonato</li>
                            <li>Começa na 1ª jornada</li>
                            <li>Da 1ª à 15ª jornada serão eliminados os 3 piores de cada jornada (caso existam mais de
                                3 pontuações na 1ª jornada iguais, será eliminado aquele que tiver o capitão com menos
                                pontos; a 2ª opção será eliminado aquele que tiver o vice capitão com menos pontos; 3º
                                opção será eliminado aquele que tiver menos golos marcados na jornada)</li>
                            <li>Da Jornada 16 a 20ª arrumam 2 piores por jornada</li>
                            <li>Da jornada 21 a 37ª arruma pior pontuação</li>
                            <li>Na última jornada restarão apenas 3 finalistas</li>
                        </ul>

                        <h4>Prémios</h4>
                        <ul class="list-unstyled">
                            <li>1º lugar&emsp;<strong>150€</strong></li>
                            <li>2º lugar&emsp;<strong>100€</strong></li>
                            <li>3º lugar&emsp;<strong>50€</strong></li>
                            <li><strong>Total 300€</strong></li>
                        </ul>



                        <!-- TAÇA criada pela app/site da fantasy -->

                        <h3>Taça criada pela App/Site da Fantasy</h3>

                        <ul class="features-list">
                            <li>Por ser definido pelo site o sorteio</li>
                        </ul>

                        <h4>Prémio</h4>
                        <ul class="list-unstyled">
                            <li><strong>50€</strong></li>
                        </ul>

                        <!-- melhor defesa -->
                        <h3>Melhor Defesa</h3>

                        <ul class="features-list">
                            <li>Jornada 10</li>
                            <li>(em caso de empate ganha quem está melhor na classificação nessa jornada)</li>
                        </ul>

                        <h4>Prémio</h4>
                        <ul class="list-unstyled">
                            <li><strong>20€</strong></li>
                        </ul>
                        <!-- melhor meio campo -->
                        <h3>Melhor Meio Campo</h3>

                        <ul class="features-list">
                            <li>Jornada 20</li>
                            <li>(em caso de empate ganha quem está melhor na classificação nessa jornada)</li>
                        </ul>

                        <h4>Prémio</h4>
                        <ul class="list-unstyled">
                            <li><strong>20€</strong></li>
                        </ul>
                        <!-- melhor ataque -->
                        <h3>Melhor Ataque</h3>

                        <ul class="features-list">
                            <li>Jornada 30</li>
                            <li>(em caso de empate ganha quem está melhor na classificação nessa jornada)</li>
                        </ul>

                        <h4>Prémio</h4>
                        <ul class="list-unstyled">
                            <li><strong>20€</strong></li>
                        </ul>
                        <!-- observações -->

                        <h3>Observações</h3>

                        <p><strong>Todos pagamentos devem ser feitos antes de começar. <br>
                            Caso de impossibilidade, falar com alguém da organização para perceber que se poder fazer.</strong>
                        </p>
                        <p><strong>Ao fim de cada prémio mensal será disponibilizado um ficheiro com pagamentos em dia e prémios
                            atualizados.</strong></p>
                        <p><strong>Lembrança para 3 primeiros classificados da liga, bem com vencedores das outras competições</strong>
                        </p>










                    </div>

                </div>
            </div>
        </div>
    </div>
</section>