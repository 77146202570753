import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/shared/database.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {


  teams: any[];
  constructor(private db: DatabaseService) {
    this.db.getAllTeams().then((val: any) => {
      this.teams = val;
      this.teams.forEach(team => {
        this.db.getProfilePic(team['team']).then((val) => {
          team['pic'] = val;
        })
        .catch((error) => {
          team['pic'] = error;

        })
      })
    })
  }
  ngOnInit(): void {
  }

}
