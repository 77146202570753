<section id="highlights" class="matches-highlights-slides owl-carousel owl-theme">
    <div class="single-matches-highlights-item highlights-bg1">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="content">
                        <h3>Prémio Mensal</h3>
                        <span>Champions League - 20 April, 2020</span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="highlights-video">
                        <a href="https://www.youtube.com/watch?v=2Le9TVyWpLY" class="video-btn popup-youtube">
                            <span>Play Video</span>
                            <i class="flaticon-play-button"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="single-matches-highlights-item highlights-bg2">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="content">
                        <h3>Prémio Semanal</h3>
                        <span>Premier League - 19 April, 2020</span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="highlights-video">
                        <a href="https://www.youtube.com/watch?v=2Le9TVyWpLY" class="video-btn popup-youtube">
                            <span>Play Video</span>
                            <i class="flaticon-play-button"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--<div class="single-matches-highlights-item highlights-bg3">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="content">
                        <h3>Matches Highlights</h3>
                        <span>Champions League - 18 April, 2020</span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="highlights-video">
                        <a href="https://www.youtube.com/watch?v=2Le9TVyWpLY" class="video-btn popup-youtube">
                            <span>Play Video</span>
                            <i class="flaticon-play-button"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="single-matches-highlights-item highlights-bg4">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="content">
                        <h3>Matches Highlights</h3>
                        <span>Premier League - 17 April, 2020</span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="highlights-video">
                        <a href="https://www.youtube.com/watch?v=2Le9TVyWpLY" class="video-btn popup-youtube">
                            <span>Play Video</span>
                            <i class="flaticon-play-button"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
</section>