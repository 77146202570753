<section id="partners" class="partners-area pt-100 pb-70"><!--bg-161616-->
    <div class="container">
        <div class="section-title">
            <h2>Equipas</h2>
            <!--<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>-->
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-4 col-md-4 col-6" *ngFor="let team of teams|slice:0:12; let i = index;">
                <div class="single-partners-box">
                    <a href="#">
                        <img [src]="team.pic + '?alt=media&token=e7897702-6859-49eb-ab07-8fca84e728ac'" alt="image">
                        <p class="mb-0">{{ team.team }}</p>
                        <small>{{ team.player }}</small>
                    </a>
                </div>
            </div>

            <div class="col-12 text-center">
                <button class="btn btn-more" routerLink="/equipas">Ver mais</button>
            </div>

            <!--<div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img2.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img3.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img4.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img5.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img6.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img7.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img8.png" alt="image">
                    </a>
                </div>
            </div>-->
        </div>
    </div>
</section>