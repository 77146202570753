<section id="shop" class="products-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Clinton Shop</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <img src="assets/img/products/img1.jpg" alt="image">

                    <div class="content">
                        <h3>Clinton Footwear</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>

                        <a href="#" class="shop-now-btn">Shop Now</a>
                    </div>

                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <img src="assets/img/products/img2.jpg" alt="image">

                    <div class="content">
                        <h3>Clinton Denim</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>

                        <a href="#" class="shop-now-btn">Shop Now</a>
                    </div>

                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <img src="assets/img/products/img3.jpg" alt="image">

                    <div class="content">
                        <h3>Clinton Underwear</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>

                        <a href="#" class="shop-now-btn">Shop Now</a>
                    </div>

                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <img src="assets/img/products/img4.jpg" alt="image">

                    <div class="content">
                        <h3>Clinton Fragrances</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>

                        <a href="#" class="shop-now-btn">Shop Now</a>
                    </div>

                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</section>