<footer class="footer-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <ul class="rules-link">
                    <li><a routerLink="/regulamentos">Regulamento</a></li>
                </ul>
            </div>
            <div class="col-lg-4 col-md-12">
                <!--<ul class="social">
                    <li><a href="#" target="_blank"><i class="flaticon-facebook-logo"></i></a></li>
                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                    <li><a href="#" target="_blank"><i class="flaticon-youtube"></i></a></li>
                </ul>-->
            </div>
            <div class="col-lg-4 col-md-12">
                <ul class="info-link">
                    <li><a routerLink="/politica-privacidade">Política de Privacidade</a></li>
                    <!--<li><a routerLink="#">Termos e Condições</a></li>-->
                </ul>
            </div>
        </div>
    </div>
</footer>